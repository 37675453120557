@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.bg-header {
    background-image: linear-gradient(rgba(255,255,255,0.925), rgba(255,255,255,0.925)), url('/img/bubbles_pos.png');
    background-repeat: no-repeat;
    background-position: 30% 55%;
}

.bg-footer {
    background-image: linear-gradient(rgba(40,71,114,0.9), rgba(0,40,90,0.9)), url('/img/bubbles_neg.png');
    background-repeat: no-repeat;
    background-position: 30% 10%;
}

@include media-breakpoint-down(sm) {
    .bg-header-xs {
        background-image: linear-gradient(rgba(255,255,255,0.925), rgba(255,255,255,0.925)), url('/img/bubbles_pos.png');
        background-repeat: no-repeat;
        background-position: 42% 57%;
    }
}

.overlay-bottom-icons {
    left: initial;
}

.fade-on-scroll {
    transition: top 0.3s;
}